.App {
  text-align: center;
  background-color: #282c34;
  overflow-x:hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: initial;
  height:100%;
}


.App-header {
  font-size: 20px;
  color: white;
  display: flex;
  flex-direction: row;
  width: 350px;
}

.App-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 350px;
  color: white;
}


