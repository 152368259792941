
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  display: block;
  background-color: #282c34;
}

#root {
  margin-top:env(safe-area-inset-top);
  margin-bottom:env(safe-area-inset-bottom);
  height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
}


.btn {
  font-size: 100%;
  padding: .5em 1em;
  min-width: 100px;
  margin: 4px;
  text-decoration: none;
  border: transparent;
  border-radius: 4px;
  cursor: pointer;
}
.btn:disabled {
  background-color: grey !important;
  opacity: 0.8;
}
.btn.primary {
  color: white;
  background-color: teal;
}
.btn.secondary {
  color: black;
  background-color: lightgrey;
}

.btn:hover {
  background-image: linear-gradient(transparent,rgba(0,0,0,.05) 40%,rgba(0,0,0,.1));
}

[type='text'],
[type='number'] {
  border-radius: 7px;
  margin-bottom: 2px;
}

label [type='radio'] {
  display: none;
}
label [type='radio'] + span {
    content: "";
    display:inline-block;
    height: 16px;
    width: 16px;
    margin-right: 5px;
    margin-left: 10px;
    vertical-align: bottom;
    border:1px solid teal;
    border-radius:50%;
    background: white;
}
label [type='radio']:checked + span:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: teal;
    border-radius: 100%;
    margin: 4px;
}
